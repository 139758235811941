import Swiper from "swiper/bundle"

class ProjectSwiper {
  constructor() {
    this.ProjectSwiper = document.querySelector(".projekt-swiper")
    if (!this.ProjectSwiper) {
      return
    }
    this.wrapper = this.ProjectSwiper.querySelector(".swiper-wrapper")
    this.swiperHeight = this.ProjectSwiper.offsetHeight
    this.mobileView = window.innerWidth < 782
    this.mobileSwiper = this.mobileView
    this.contentSlides = this.ProjectSwiper.querySelectorAll(".swiper-slide--projekt-content")

    this.showHideSlides()
    this.create()
    this.events()
    this.initialized = true
  }

  events() {
    this.ProjectSwiper.addEventListener("click", e => {
      console.log("click", e.target)
      if (this.ProjectSwiper.classList.contains("swiper-prev")) this.swiper.slidePrev()
      else if (this.ProjectSwiper.classList.contains("swiper-next")) this.swiper.slideNext()
    })

    this.ProjectSwiper.addEventListener("mousemove", e => {
      // console.log("mouse move", e.offsetY, this.swiperHeight)
      if (e.offsetY < this.swiperHeight / 2) {
        this.ProjectSwiper.classList.add("swiper-prev")
        this.ProjectSwiper.classList.remove("swiper-next")
      } else {
        this.ProjectSwiper.classList.add("swiper-next")
        this.ProjectSwiper.classList.remove("swiper-prev")
      }
    })

    // add event listener for resize
    window.addEventListener("resize", () => {
      this.swiperHeight = this.ProjectSwiper.offsetHeight
      this.mobileView = window.innerWidth < 782
      if (this.mobileView !== this.mobileSwiper) {
        this.mobileSwiper = this.mobileView
        this.destroy()
        this.showHideSlides()
        this.create()
      }
    })
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
  showHideSlides() {
    this.contentSlides.forEach((slide, index) => {
      if (this.mobileView) {
        slide.classList.remove("swiper-slide")
        slide.style.display = "none"
      } else {
        slide.classList.add("swiper-slide")
        slide.style.display = "block"
        // move slide to the end of the wrapper
        this.wrapper.appendChild(slide)
      }
    })
  }

  create() {
    this.swiper = new Swiper(this.ProjectSwiper, {
      // Optional parameters
      direction: this.mobileSwiper ? "horizontal" : "vertical",
      loop: true,
      slidesPerView: 1,
      spaceBetween: "45px",
      autoHeight: true,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        type: "fraction"
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      mousewheel: {
        forceToAxis: true,
        thresholdDelta: 20,
        thresholdTime: 300,
        releaseOnEdges: true
      }
      //   breakpoints: {
      //     782: {
      //       direction: "vertical"
      //     }
      //   }
    })
  }
}

export default ProjectSwiper
