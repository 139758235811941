import "./editor.scss"
import "../css/style.scss"

// Our modules / classes

// Instantiate a new object using our modules/classes

// import ImagesLoaded from "imagesloaded"
// import Lazyload from "lazyload"

// Our modules / classes
import ProjectSwiper from "./modules/ProjectSwiper"
import FadeIn from "./modules/FadeIn"

var debug = true
const mobileBreakpoint = 782
let mobileView = false
let initialLoad = false // first load of the page only relevant for page tabs opening

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
let headerHeight = 0

let historyUpdate = false // to push to history or not
let autoplayOn = true
let autoplaySpeed = 2500
const manualSpeed = 600
let autoplayDelay = 1000
let orientationLandscape = true

let scrollYPositions = {}

let currentContentID = -1
let prevContentID = -1

let navOpen = false // initially closed
let navChanging = false
let navChangingTimeout = null
const navChangingTime = 330
let autoScrolling = false

let requestAllPartsInterval = null
let titleGrid // grid with isotope
let picGrid // grid with isotope arbiten pics links

let projectCategoryLinks
let projectTitleLinks
let projectPictureLinks

let viewTitlesIcon // in mobile view: lets you show/hide project titles in the archvive
let viewPicturesIcon // either titles or pictures selected
let viewCategoriesIcon

let mouseStartX, mouseStartY

let scrollY = 0

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working
  // console.log("root url:", localized.root_url)

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    false
  )

  // const DateTime = luxon.DateTime;

  let pathname = window.location.pathname
  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  let blogName = document.querySelector('meta[name="name"]').content
  const parts = blogName.split(" - ")
  blogName = parts[0]
  let blogDescription = parts[1]
  // const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription)

  // -----------------------------------------------
  const pageTabs = $(".page-tab") // tabs for the pages home, archive and info for projects
  const projectInfo = $(".projekt-info")
  const projectInfoTablink = $(".projekt-info__title")
  const projectInfoTitle = $(".projekt-info__title-title")
  const projectInfoContent = $(".projekt-info__content")

  const openMenu = $(".nav-icon")
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-header__menu")

  const siteTitle = $(".site-title")
  const siteTitleLink = $(".site-title__link")

  const menuItems = $(".menu-item")
  const menuNavLinks = $(".menu-item__link")

  const contentContainer = $(".content-container")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string

  menuItems.filter(".current-menu-item").data("object-id") == homeID ? (initialLoad = true) : (initialLoad = false) // if frontpage is current menu item -> initial load is true to avoid opening the page tab

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))
  document.documentElement.style.setProperty("--highlight-color", $("body").data("highlightcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  setHeaderHeight()
  checkMobileView()

  const projectSwiper = new ProjectSwiper()
  const fadeIn = new FadeIn()

  // hide and fade incontent elements with visibility hidden
  contentContainer.hide()
  contentContainer.css("visibility", "visible")
  contentContainer.fadeIn()

  // menu and title navigation ------------------------------------------------------------------------------------------------
  menuNavLinks.add(siteTitleLink).on("click", function (e) {
    // console.log("menu nav link clicked")

    const menuItem = $(this).parent()
    const isPage = menuItem.hasClass("menu-item-object-page")
    const isHome = menuItem.hasClass("menu-item-home")
    const isProject = menuItem.hasClass("menu-item-object-projekt")
    const isArchive = menuItem.hasClass("menu-item-type-post_type_archive")
    const id = isHome ? homeID : menuItem.data("object-id")

    // console.log("Page:", isPage, "object id", id, homeID, "is home: ", isHome)

    if (isPage || isProject || isHome || isArchive) {
      // links in the menu are either pages or project archive
      e.preventDefault()

      const slug = $(this).attr("href").split(`${window.location.origin}/`)[1]
      // console.log("slug", slug)

      // history only gets updated when clicking on a different menu item, and not when navigating by url (gotolocation)
      const ref = `${window.location.origin}/${slug}`

      if (window.location.href !== ref) {
        window.history.pushState(null, null, ref)
        // console.log("updated history ", ref);

        // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
        if (slug !== "") {
          // not home -> page | blogname
          document.title = $(this).children().text() + " | " + blogName
        } else {
          // is home -> blogname | blogdescription
          document.title = blogName + " | " + blogDescription
        }
      }
      if (isHome) {
        openPageLink($(this), id)
      } else if (isPage) {
        openPageLink($(this))
      } else if (isProject) {
        // project
        if (isArchive) {
          openProjectArchive(slug)
        } else {
          openProjectWithID(id)
        }
      }
    }
  })

  // open menu
  openMenu.on("click", function (e) {
    e.preventDefault()
    // console.log("open menu")
    if ($html.hasClass("nav-open")) {
      closeNav()
    } else {
      openNav()
    }
  })

  function openNav() {
    if (!navChanging) {
      scrollY = window.scrollY
      navChanging = true
      $html.addClass("nav-opening")
      openMenu.addClass("checked")
      navChangingTimeout = setTimeout(() => {
        navOpen = true
        $html.addClass("nav-open")
        $html.removeClass("nav-opening")
        navChanging = false
      }, navChangingTime)
    }
  }

  function closeNav() {
    if (!navChanging) {
      navChanging = true
      $html.addClass("nav-closing")
      $html.removeClass("nav-open")
      window.scrollTo(0, scrollY)
      openMenu.removeClass("checked")

      navChangingTimeout = setTimeout(() => {
        navOpen = false
        $html.removeClass("nav-closing")
        window.scrollTo(0, scrollY)
        navChanging = false
      }, navChangingTime)
    }
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link, pageID) {
    // console.log("open page link", $link.attr("href"))

    const menuItem = $link.parent() // menu item of link

    pageID = pageID ?? menuItem.data("object-id")

    setCurrentMenuItem(menuItem)

    openPageWithID(pageID)
  }

  function setCurrentMenuItem(menuItem) {
    //
    menuItems.removeClass("current-menu-item") // remove current menu from all menu items
    menuItem.addClass("current-menu-item") // new current menu item

    const currentInd = menuItem.index()
    // console.log("curr menu index", currentInd)
    menuItems.each(function (ind) {
      if (ind <= currentInd) {
        $(this).addClass("open")
      } else {
        $(this).removeClass("open")
      }
    })
    // console.log("menu item index", menuItem.index())
  }

  function openPageWithID(pageID) {
    // console.log("open page with id", pageID)
    if (pageID) {
      // hide all other page and post item content and the images

      setCurrentContentID(pageID)

      if (!initialLoad) {
      } else {
        initialLoad = false
      }
    }
  }

  function openProjectWithSlug(slug) {
    // console.log("open project with slug", slug)

    // check if single project or archive and get the id
    $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${slug}`, post => {
      // console.log("got post", post)
      if (post.ID > 0) {
        // project found
        // if (debug) console.log("project id found -> open project with id", post.ID)
        openProjectWithID(post.ID)
      } else {
        // is not a single project -> project archive /arbeiten or /arbeiten/category/xxxx/
        openProjectArchive(slug)
      }
    })
  }

  function openProjectWithID(projectID) {
    // if one project is opened all projects are loaded. but with image lazy loading
    if (projectID) {
      console.log("open project with id", projectID)

      // get arbeiten menu item
      const menuitem = menuItems.filter('[data-slug="projekte/"]')
      setCurrentMenuItem(menuitem)

      setCurrentContentID(projectID)
    }
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function openProjectArchive(slug) {
    // console.log("open the projekte archive", slug)

    // get arbeiten menu item
    const menuitem = menuItems.filter('[data-slug="projekte/"]')
    setCurrentMenuItem(menuitem)

    // hide posts container containing all arbeiten posts
    let projectArchive = $("#projekt-archive")

    setCurrentContentID("projekt-archive")
  }

  // function addLazyloadToElement($elem) {
  //   if ($elem.length) {
  //     // https://github.com/tuupola/lazyload
  //     // $("img.lazyload").lazyload();
  //     // lazyload();

  //     // const elem = document.querySelector(`[id="${elemID}"]`);
  //     const elem = $elem[0]
  //     const images = elem.querySelectorAll("img.lazyload")
  //     // console.log("lazyload images", images, " in container ", $elem)

  //     new Lazyload(images, {
  //       root: null,
  //       rootMargin: "50px",
  //       threshold: 0
  //     })

  //     // add load event listener for lazyload images
  //     $elem.find(`img.lazyload`).on("load", function () {
  //       // console.log("img on elem xx loaded", $elem.attr('id'));
  //       $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
  //     })
  //   }
  // }

  // handle pathname ------------------------------------------------------------------------------------------------
  gotoLocation(pathname)

  function gotoLocation(pathname) {
    // console.log("goto to location:", pathname)

    // get post info
    if (pathname === "/") {
      // is home page
      // console.log("is home")
      openPageLink(siteTitleLink)
      // --------------------------------------------------------
    } else if (pathname.startsWith("/projekte/")) {
      // single arbeit or arbeit archive
      openProjectWithSlug(pathname)
    } else {
      // not home nor project
      // console.log("not home check menu items", $("li.menu-item").length)
      // first check if it's a menu item. exclude the lang menu item
      let found = false
      menuNavLinks.each(function () {
        if ($(this).attr("href").includes(pathname)) {
          found = true
          // console.log("menu link found", $(this).attr("href"))
          // if page
          if ($(this).parent().hasClass("menu-item-object-page")) openPageLink($(this))
          // if project
          else if ($(this).parent().hasClass("menu-item-object-arbeit")) openProjectWithSlug(pathname)
          return false
        }
      })
      if (!found) {
        // was not a menu link
        // console.log("not menu link")
        // console.log("url is something else")
        // something other -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(pageID)
            } else if (post.post_type == "projekt") {
              openProjectWithID(post.ID)
            } else {
              // hide all other post items
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else {
            // is not a post -> show all post item container
            // hide all other post items
            $(".post-item-container").fadeIn()
            $("li.menu-item").removeClass("current-menu-item")
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      setHeaderHeight()
      calcVhPropertyFromClientHeight()
      checkMobileView()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
      if (viewCategoriesIcon) {
        if (viewCategoriesIcon.hasClass("selected")) $(".post-item__categories").show()
        else $(".post-item__categories").hide()
        if (!viewTitlesIcon.hasClass("selected")) $(".post-item__titles").hide()
        if (!viewPicturesIcon.hasClass("selected")) $(".post-item__pictures").hide()
      }
    } else {
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      $(".post-item__categories").show()
      $(".post-item__titles").show()
      $(".post-item__pictures").show()
    }
    // console.log("window width", $(window).width())
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function setHeaderHeight() {
    // get header height only when nav closed. only for mobile relevant
    if (!navOpen) {
      headerHeight = siteHeader.outerHeight()
      $(":root").css("--headerHeight", `${headerHeight}px`)
    }
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);

    //
    // const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    // const h = document.documentElement.clientHeight
    // console.log("ios vh:", vh * 100, "vh:", h)
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("on popstate", event);
    gotoLocation(window.location.pathname)
  }

  jQuery(document.body).on("post-load", function () {
    // New content has been added to the page.
    // console.log("post-load")
  })

  // for desktop: overflow auto for .content-container not body -> get scroll position from content-container
  document.querySelector(".content-container").addEventListener("scroll", function (e) {
    // console.log("scroll in content container", e.target.scrollTop)
    scrollYPositions[currentContentID] = e.target.scrollTop
  })

  function getIDFromClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }
})
