class FadeIn {
  constructor() {
    this.elements = jQuery(".image-container, .projekt-item")
    if (!this.elements.length) return

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const element = jQuery(entry.target)
          if (element.hasClass("image-container")) element.addClass("image-container--is-visible")
          if (element.hasClass("projekt-item")) element.addClass("projekt-item--is-visible")
          this.observer.unobserve(element[0])
        }
      })
    })

    this.elements.each((i, element) => {
      // add intersection observer to each element

      this.observer.observe(element)
    })

    this.initialized = true
    console.log("FadeIn module initialized", this.elements)
  }
}

export default FadeIn
